import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
import ServiceRelatedTermsList from '../../../components/ServiceRelatedTermsList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="用語・表記ルール - コンテンツ" mdxType="SEO" />
    <PageTitle title="用語・表記ルール" enTitle="Vocabulary" mdxType="PageTitle" />
    <h2 {...{
      "id": "textlint",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#textlint",
        "aria-label": "textlint permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`textlint`}</h2>
    <p>{`一部のプロジェクトでは`}<a parentName="p" {...{
        "href": "https://github.com/textlint/textlint"
      }}>{`textlint`}</a>{`を用いた機械的な表記チェックを試験しています。`}</p>
    <p>{`textlintでは下記のルールを元にチェックをしています。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/openameba/textlint-rule-preset-ameba/blob/main/lib/textlint-rule-preset-ameba.js"
        }}>{`日本語表記ルール`}</a>{`（Textlintのプリセット）`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/openameba/textlint-rule-preset-ameba/blob/main/lib/prh-service.yml"
        }}>{`サービスの用語`}</a></li>
    </ul>
    <h2 {...{
      "id": "用語表記ルール一覧",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%94%A8%E8%AA%9E%E8%A1%A8%E8%A8%98%E3%83%AB%E3%83%BC%E3%83%AB%E4%B8%80%E8%A6%A7",
        "aria-label": "用語表記ルール一覧 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`用語表記ルール一覧`}</h2>
    <ServiceRelatedTermsList mdxType="ServiceRelatedTermsList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      